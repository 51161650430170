<template>
  <v-app class="case-detail">
    <div class="type-1" v-if="data.type == 1">
      <p class="title">
        {{ data.title }}
      </p>
      <div class="content" v-if="data.content">
        <p v-for="v in data.content" :key="v.length">
          {{ v.value }}
        </p>
      </div>
      <video v-if="data.url && data.urlType == 'video'" :src="data.url" controls :style="{width: screenWidth}"></video>
      <img v-if="data.url && data.urlType == 'img'" :style="{width: screenWidth}" :src="data.url" />
      <div class="imgurl" v-if="data.imgUrl">
        <img v-for="(v, k) in data.imgUrl" :style="{width: screenWidth}" :key="k" :src="v" />
      </div>

      <v-carousel
        v-if="data.lunbo"
        cycle
        :height="data.lunbo.height"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item
          v-for="(slide, i) in data.lunbo.imgUrl"
          :key="i"
        >
          <v-sheet
            height="100%"
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <img :src="slide" :style="data.lunbo.imgStyle" alt="" />
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>

      <div class="bjd name" v-if="data.name">
        <p>名称：</p>
        <p> {{ data.name }} </p>
      </div>
      <div class="bjd introduce" v-if="data.introduce">
        <p>项目介绍：</p> 
        <p
          v-for="(v,k) in data.introduce"
          :key="k"
        > 
          {{ v }} 
        </p>
      </div>
      <div class="bjd advantage" v-if="data.advantage">
        <p>技术优势：</p>
        <p
          v-for="(v,k) in data.advantage"
          :key="k"
        > 
          {{ v }} 
        </p>
      </div>
      <div class="bjd effect" v-if="data.effect">
        <p>项目效果：</p>
        <p
          v-for="(v,k) in data.effect"
          :key="k"
        > 
          {{ v }} 
        </p>
      </div>
    </div>
    <div class="type-2" v-if="data.type == 2 || source == 'home'">
      <p class="title"> {{ data.title }} </p>
      <div v-for="(v,k) in data.content" :key="k">
        <p
          v-if="v.type == 'text'"
          :class="v.class"
          v-html="v.value"
        >
          {{ v.value }}
        </p>
        <img
          v-if="v.type == 'img'"
          :src="v.value"
          :style="{width: screenWidth}"
          alt=""
        />
        <video 
          v-if="v.type == 'video'" 
          :style="{width: screenWidth}" 
          :src="v.value" controls>
        </video>
      </div>
    </div>
  </v-app>
</template>

<script>
import textData from '@/static/textData.js'

export default {
  name: 'wmyH5h5CaseDetail',
  data() {
    return {
      data: {},
      source: this.$route.query.source,
      screenWidth: window.innerWidth-40 + 'px'
    }
  },
  created() {
    if(this.$route.query.source == 'home') {
      this.data = textData.Home.warp5.list[this.$route.query.key]
    }
    if(this.$route.query.source == 'case') {
      this.data = textData.CooperationCase[this.$route.query.levelOneIndex].tab[this.$route.query.levelTwoIndex].tabItem[this.$route.query.vid].detail
    }
    if(this.$route.query.source == 'qita') {
      this.data = textData.anli[this.$route.query.anli].detail
      console.log(this.data)
    }
    console.log(this.data)
    console.log(window.innerWidth)
  },
  methods: {
    
  }

}

</script>

<style lang="scss" scoped>
.case-detail {
  padding: 30px 20px 0;
  margin: 50px auto 0;
  font-size: 14px !important;

  overflow-x: hidden;
}

.type-1 {
  // text-align: center;
  .title {
    font-size: 22px !important;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: start;
  }
  .content {
    text-align: start;
  }
  .imgurl {
    margin-bottom: 25px;
  }
  video {
    // width: 350px;
    height: 210px;
    margin-bottom: 35px;
    background: #000;
  }
  .v-carousel {
    margin-bottom: 35px;
    ::v-deep .v-btn--icon {
      color: skyblue !important;
    }
  }
  .bjd {
    margin-bottom: 25px;
    text-align: start;
    p {
      padding: 0 25px;
      position: relative;
      margin-bottom: 10px !important;
      line-height: 20px;
      color: #666;
      &::before {
        content: "";
        width: 1px;
        height: 1px;
        background: #000;
        border-radius: 50%;
        transform:scale(4);
        position: absolute;
        left: 10px;
        top: 9px;
      }
      &:first-child {
        padding: 0;
        &::before {
          display: none;
        }
      }
    }
    p:nth-of-type(1) {
      color: #333;
    }
  }
}

.type-2 {
  // text-align: center;
  margin-bottom: 20px;
  .title {
    font-size: 22px !important;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: start;
  }
  p {
    margin-bottom: 5px;
    text-align: start;
    line-height: 20px;
  }
  img {
    margin: 10px auto;
  }
  video {
    margin: 20px 0;
  }
  .mt-20 {
    margin-top: 20px;
  }
}

</style>